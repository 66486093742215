<template>
<div>
    <el-collapse>
        <el-collapse-item>
            <template slot="title">
                <i class="header-icon el-icon-s-operation"></i>筛选
                <span class="totalwrp">总条数:{{total}}</span>
            </template>
            <div class="sixa">
                <el-row :gutter="8">
                    <el-col :span="5" class="searchwrp">
                        <label for="">搜索: </label>
                        <el-input type="text" v-model="keyword" clearable></el-input>
                    </el-col>
                    <el-col :span="5">
                        <el-button type="primary" round @click="searchKeyWord">筛选</el-button>
                    </el-col>
                    <el-col :span="5">
                        <el-button type="primary" round plain @click="addProduct">添加</el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="8">
                    <el-col :span="13">
                        <label for="">时间范围：</label>
                        <el-date-picker v-model="dateRang" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="setRangDate">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="5">
                        <el-button type="primary" round plain @click="exprotWalfare">导出</el-button>
                    </el-col>
                </el-row>
            </div>
        </el-collapse-item>
    </el-collapse>
    <el-table :data="orders" stripe tooltip-effect="dark" fit row-class-name="rows">
        <el-table-column label="ID" width="70">
            <template #default="scope">
                {{ scope.row.Product_ID }}
            </template>
        </el-table-column>
        <el-table-column label="名称" width="360" show-overflow-tooltip>
            <template #default="scope">
                {{ scope.row.Product_Name }}
            </template>
        </el-table-column>
        <el-table-column label="价格">
            <template #default="scope">
                {{ scope.row.Product_SellPrice }}
            </template>
        </el-table-column>
        <el-table-column label="运费">
            <template #default="scope">
                {{ scope.row.Product_Freight }}
            </template>
        </el-table-column>
        <el-table-column label="状态" width="100">
            <template #default="scope">
                {{scope.row.Product_Onshelf==1?"上架中":"已下架"}}
            </template>
        </el-table-column>

        <el-table-column label="操作" width="350" fixed="right">
            <template #default="scope">
                <el-button size="mini" type="primary" plain round @click="beforeEdit(scope.row)">
                    修改</el-button>
                <el-button size="mini" type="primary" plain round @click="editReStore(scope.row)">库存</el-button>
                <el-button size="mini" type="primary" plain round @click="showPicList(scope.row)">图片</el-button>
                <el-popconfirm confirm-button-text="好的" cancel-button-text="再考虑" icon="el-icon-info" icon-color="red" title="确定要删除吗？" @confirm="deletOrder(scope.row.Product_ID)">
                    <template #reference>
                    <el-button type="warning" size="mini" slot="reference" plain round>删 除</el-button>
                     </template>
                </el-popconfirm>
                <span v-if="scope.row.Product_Onshelf == 1">
                    <el-popconfirm confirm-button-text="好的" cancel-button-text="再考虑" icon="el-icon-info" icon-color="red" title="确定要下架吗？" @confirm="unselfOrder(scope.row.Product_ID)">
                        <template #reference>
                        <el-button type="warning" size="mini" plain round slot="reference">下 架</el-button>
                         </template>
                    </el-popconfirm>
                </span>
                <span v-else>
                    <el-button type="primary" size="mini" plain round @click="onselfOrder(scope.row.Product_ID)">上 架</el-button>
                </span>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="setPageIndx">
    </el-pagination>
    <el-dialog v-model="picList" :modal="true" width="47%">
        <el-table class="imgTable" title="活动图片" :data="picListData" stripe tooltip-effect="dark" fit row-class-name="rows">
            <el-table-column label="#" width="100">
                <template #default="scope">
                    {{scope.row.Images_ID}}
                </template>
            </el-table-column>
            <el-table-column label="图片">
                <template #default="scope">
                    <img :src="domin+scope.row.Images_Url">
                </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
                <template #default="scope">
                    <el-popconfirm confirm-button-text="好的" cancel-button-text="再考虑" icon="el-icon-info" icon-color="red" title="确定要删除吗？" @confirm="deletePic(scope.row)">
                        <template #reference>
                            <el-button size="mini" type="primary" plain round slot="reference">删除</el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div class="imgUploadWrp">
            <el-upload class="upload-demo" action="/Ajax/upload_json.ashx?dir=image" name="imgFile" multiple :on-success="successUploadImg" :show-file-list="false">
                <el-button size="small" type="primary">上传图片</el-button>
            </el-upload>
        </div>
    </el-dialog>
    <el-dialog title="添加福利产品" v-model="addProductVisiable" :modal="true" width="47%" :close-on-click-modal="false">
        <el-form label-position="left" label-width="100px">
            <el-form-item label="名称">
                <el-input v-model="formData.Product_Name"></el-input>
            </el-form-item>
            <el-form-item label="分类">
                <el-select v-model="formData.Product_Brand" placeholder="请选择">
                    <el-option v-for="item in Brands" :key="item.Brand_ID" :value="item.Brand_ID" :label="item.Brand_Name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属商家">
                <el-select v-model="formData.Product_Merchant" placeholder="请选择" disabled>
                    <el-option v-for="item in sellers" :key="item.Merchant_ID" :value="item.Merchant_ID" :label="item.Merchant_Name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="价格">
                <el-input v-model="formData.Product_SellPrice" placeholder="只需输入价格数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="原价">
                <el-input v-model="formData.Product_OriPrice" placeholder="只需输入原价数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="积分">
                <el-input v-model="formData.Product_Discount" placeholder="只需输入积分数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="单次购买数">
                <el-input v-model="formData.Product_BuyCountLimit" type="number"></el-input>
            </el-form-item>
            <el-form-item label="单月购买次数">
                <el-input v-model="formData.Product_BuySeriesLimit" type="number"></el-input>
            </el-form-item>
            <el-form-item label="排序">
                <el-input v-model="formData.Product_Sort" type="number"></el-input>
            </el-form-item>
            <el-form-item label="加入推荐">
                <el-checkbox v-model="formData.Product_ChildClassify1">教育--研学实践</el-checkbox>
                <el-checkbox v-model="formData.Product_Hot">福利--图片滚动</el-checkbox>
                <el-checkbox v-model="formData.Product_Commend">福利--福利推荐</el-checkbox>
            </el-form-item>
            <el-form-item label="选项类别一">
                <el-input v-model="formData.Product_ColorAlias" type="text"></el-input>
            </el-form-item>
            <el-form-item label="选项类别二">
                <el-input v-model="formData.Product_SizeAlias" type="text"></el-input>
            </el-form-item>
            <el-form-item label="选项类别三">
                <el-input v-model="formData.Product_VarietyAlias" type="text"></el-input>
            </el-form-item>
            <el-form-item label="运费">
                <el-input v-model="formData.Product_Freight" placeholder="只需输入运费数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="库存*">
                <el-input v-model="formData.Product_StockTotal" placeholder="只需输入库存数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="销量">
                <el-input v-model="formData.Product_Sales" placeholder="只需输入销量数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="联系人名称">
                <el-input v-model="formData.Product_ContactName" type="text"></el-input>
            </el-form-item>
            <el-form-item label="联系人手机">
                <el-input v-model="formData.Product_ContactPhone" type="tel"></el-input>
            </el-form-item>
            <el-form-item label="联系人地址">
                <el-input v-model="formData.Product_ContactAddress" type="text"></el-input>
            </el-form-item>
            <el-form-item label="商品原价价格">
                <el-input v-model="formData.Product_PinPrice" type="number" placeholder="只需输入价格数字，不需要增加单位或其他文字"></el-input>
            </el-form-item>
            <el-form-item label="福利报名时间*">
                <el-date-picker v-model="formData.Product_FlashStart" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择开始日期时间"></el-date-picker>
                <el-date-picker v-model="formData.Product_FlashEnd" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择结束日期时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="地址坐标*">
                <el-input v-model="formData.Product_Position" type="text" placeholder="经纬度"></el-input>
                <a target="_blank" href="https://lbs.qq.com/getPoint/">拾取坐标</a>
            </el-form-item>
            <el-form-item label="封面图片">
                <el-input v-model="formData.Product_ImageUrl" type="text"></el-input>
                <el-upload class="upload-demo" action="/Ajax/upload_json.ashx?dir=image" name="imgFile" multiple :on-success="UploadImg" :show-file-list="false">
                    <el-button size="small" type="primary">上传图片</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="福利滚动图片">
                <el-input v-model="formData.Product_ImageBanner" type="text" placeholder="图片宽高比5:2"></el-input>
                <el-upload class="upload-demo" action="/Ajax/upload_json.ashx?dir=image" name="imgFile" multiple :on-success="UploadImgBanner" :show-file-list="false">
                    <el-button size="small" type="primary">上传图片</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="兑换需知">
                <editor v-model="formData.Product_Brief"></editor>
            </el-form-item>
            <el-form-item label="商品详情">
                <editor v-model="formData.Product_Detail"></editor>
            </el-form-item>
        </el-form>
        <div class="btm-btns">
            <el-button plain round @click="addProductVisiable=false"> 取消</el-button>
            <el-button plain round tpye="primary" @click="sureToaddProduct">确定</el-button>
        </div>
    </el-dialog>
    <el-dialog title="修改福利产品" v-model="editProductVisiable" :modal="true" width="47%" :close-on-click-modal="false">
        <el-form label-position="left" label-width="100px">
            <el-form-item label="名称">
                <el-input v-model="formData.Product_Name"></el-input>
            </el-form-item>
            <el-form-item label="分类">
                <el-select v-model="formData.Product_Brand" placeholder="请选择">
                    <el-option v-for="item in Brands" :key="item.Brand_ID" :value="item.Brand_ID" :label="item.Brand_Name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属商家">
                <el-select v-model="formData.Product_Merchant" placeholder="请选择" disabled>
                    <el-option v-for="item in sellers" :key="item.Merchant_ID" :value="item.Merchant_ID" :label="item.Merchant_Name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="价格">
                <el-input v-model="formData.Product_SellPrice" placeholder="只需输入价格数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="原价">
                <el-input v-model="formData.Product_OriPrice" placeholder="只需输入原价数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="积分">
                <el-input v-model="formData.Product_Discount" placeholder="只需输入积分数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="单次购买数">
                <el-input v-model="formData.Product_BuyCountLimit" type="number"></el-input>
            </el-form-item>
            <el-form-item label="单月购买次数">
                <el-input v-model="formData.Product_BuySeriesLimit" type="number"></el-input>
            </el-form-item>
            <el-form-item label="排序">
                <el-input v-model="formData.Product_Sort" type="number"></el-input>
            </el-form-item>
            <el-form-item label="加入推荐">
                <el-checkbox v-model="formData.Product_ChildClassify1" @change="setVal">教育--研学实践</el-checkbox>
                <!-- <el-checkbox v-model="formData.Product_ChildClassify2" @change="setVal">医保--服务队活动</el-checkbox> -->
                <el-checkbox v-model="formData.Product_Hot" @change="setVal">福利--福利滚动</el-checkbox>
                <el-checkbox v-model="formData.Product_Commend" @change="setVal">福利--福利推荐</el-checkbox>
            </el-form-item>
            <el-form-item label="选项类别一">
                <el-input v-model="formData.Product_ColorAlias" type="text"></el-input>
            </el-form-item>
            <el-form-item label="选项类别二">
                <el-input v-model="formData.Product_SizeAlias" type="text"></el-input>
            </el-form-item>
            <el-form-item label="选项类别三">
                <el-input v-model="formData.Product_VarietyAlias" type="text"></el-input>
            </el-form-item>
            <el-form-item label="运费">
                <el-input v-model="formData.Product_Freight" placeholder="只需输入运费数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="库存">
                <el-input v-model="formData.Product_StockTotal" placeholder="只需输入库存数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="销量">
                <el-input v-model="formData.Product_Sales" placeholder="只需输入销量数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="联系人名称">
                <el-input v-model="formData.Product_ContactName" type="text"></el-input>
            </el-form-item>
            <el-form-item label="联系人手机">
                <el-input v-model="formData.Product_ContactPhone" type="tel"></el-input>
            </el-form-item>
            <el-form-item label="联系人地址">
                <el-input v-model="formData.Product_ContactAddress" type="text"></el-input>
            </el-form-item>
            <el-form-item label="商品原价价格">
                <el-input v-model="formData.Product_PinPrice" type="number" placeholder="只需输入价格数字，不需要增加单位或其他文字"></el-input>
            </el-form-item>
            <el-form-item label="福利报名时间">
                <el-date-picker v-model="formData.Product_FlashStart" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择开始日期时间"></el-date-picker>
                <el-date-picker v-model="formData.Product_FlashEnd" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择结束日期时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="地址坐标">
                <el-input v-model="formData.Product_Position" type="text" placeholder="经纬度"></el-input>
                <a target="_blank" href="https://lbs.qq.com/getPoint/">拾取坐标</a>
            </el-form-item>
            <el-form-item label="封面图片">
                <el-input v-model="formData.Product_ImageUrl" type="text"></el-input>
                <el-upload class="upload-demo" action="/Ajax/upload_json.ashx?dir=image" name="imgFile" multiple :on-success="UploadImg" :show-file-list="false">
                    <el-button size="small" type="primary">上传图片</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="福利滚动图片">
                <el-input v-model="formData.Product_ImageBanner" type="text" placeholder="图片宽高比5:2"></el-input>
                <el-upload class="upload-demo" action="/Ajax/upload_json.ashx?dir=image" name="imgFile" multiple :on-success="UploadImgBanner" :show-file-list="false">
                    <el-button size="small" type="primary">上传图片</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="兑换需知">
                <editor v-model="formData.Product_Brief"></editor>
            </el-form-item>
            <el-form-item label="商品详情">
                <editor v-model="formData.Product_Detail"></editor>
            </el-form-item>
        </el-form>
        <div class="btm-btns">
            <el-button plain round @click="editProductVisiable=false"> 取消</el-button>
            <el-button plain round tpye="primary" @click="sureToeditProduct">确定</el-button>
        </div>
    </el-dialog>

    <el-dialog title="地图" v-model="mapShow" :modal="true" width="87%" :close-on-click-modal="false">
        <div id="tmap" ref="tmap"></div>
    </el-dialog>
    <el-dialog title="添加类目名" v-model="addTypeVisable" :modal="true" width="260px" :close-on-click-modal="false">
        <el-row>
            <el-input v-model="typeName" type="text"></el-input>
        </el-row>
        <el-row style="text-align:center;padding-top:20px">
            <el-button @click="addTypeVisable=false;typeName=''">取消</el-button>
            <el-button @click="addTypeInArr">确定</el-button>
        </el-row>

    </el-dialog>
    <el-dialog title="修改库存" v-model="storeEditVisiable" :modal="true" width="470px" :close-on-click-modal="false">
        <el-form label-position="left" label-width="100px">
            <el-form-item label="售价">
                <el-input v-model="storeSale" placeholder="只需输入售价数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="库存">
                <el-input v-model="storeTotal" placeholder="只需输入库存数字，不需要增加单位或其他文字" type="number"></el-input>
            </el-form-item>
            <el-form-item label="货号">
                <el-input v-model="storeNum" type="text"></el-input>
            </el-form-item>
            <el-form-item label="图片">
                <el-input v-model="store_ImageUrl" type="text"></el-input>
                <el-upload class="upload-demo" action="/Ajax/upload_json.ashx?dir=image" name="imgFile" multiple :on-success="storeUploadImg" :show-file-list="false">
                    <el-button size="small" type="primary">上传图片</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <div class="btm-btns">
            <el-button plain round @click="storeEditVisiable=false"> 取消</el-button>
            <el-button plain round tpye="primary" @click="sendEditStore">确定</el-button>
        </div>
    </el-dialog>
    <el-dialog title="库存" v-model="storeVisiable" :modal="true" width="47%" :close-on-click-modal="false">
        <div class="typesWrp">
            <el-row>
                <el-col :span="24">
                    <el-button plain round @click="addType(0)">颜色+</el-button>
                    <div>
                        <span class="typeitem" v-for="(item,index) in colors" :key="index">{{item}} <i class="el-icon-close" @click="delItem(index,0)"></i> </span>
                    </div>
                </el-col>
                <el-col :span="24">
                    <el-button plain round @click="addType(1)">尺寸+</el-button>
                    <div>
                        <span class="typeitem" v-for="(item,index) in sizes" :key="item">{{item}} <i class="el-icon-close" @click="delItem(index,1)"></i> </span>
                    </div>
                </el-col>
                <el-col :span="24">
                    <el-button plain round @click="addType(2)">种类+</el-button>
                    <div>
                        <span class="typeitem" v-for="(item,index) in kinds" :key="item">{{item}} <i class="el-icon-close" @click="delItem(index,2)"></i> </span>
                    </div>
                </el-col>
            </el-row>
            <el-row style="text-align:center">
                <el-button type="primary" plain round @click="compareArr">确认修改</el-button>
            </el-row>
        </div>
        <el-table :data="storeList" stripe tooltip-effect="dark" fit row-class-name="rows">
            <el-table-column label="颜色">
                <template #default="scope">
                    {{scope.row.Stock_Color}}
                </template>
            </el-table-column>
            <el-table-column label="尺寸">
                <template #default="scope">
                    {{scope.row.Stock_Size}}
                </template>
            </el-table-column>
            <el-table-column label="种类">
                <template #default="scope">
                    {{scope.row.Stock_Variety}}
                </template>
            </el-table-column>
            <el-table-column label="售价">
                <template #default="scope">
                    {{scope.row.Stock_SellPrice}}
                </template>
            </el-table-column>
            <el-table-column label="库存">
                <template #default="scope">
                    {{scope.row.Stock_Total}}
                </template>
            </el-table-column>
            <el-table-column label="图片">
                <template #default="scope">
                    <img style="width:100px" :src="domin + scope.row.Stock_ImageUrl" alt="">
                </template>
            </el-table-column>
            <el-table-column label="货号">
                <template #default="scope">
                    {{scope.row.Stock_Number}}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button type="primary" plain round @click="editStore(scope.row)">
                        修改</el-button>
                </template>
            </el-table-column>

        </el-table>

    </el-dialog>
</div>
</template>

<script>
let app = null
import Editor from "@/components/tmeditor";
import lo from 'lodash'
export default {
    data() {
        return {
            domin: "",
            dateRang: "",
            formData: {
                Product_Name: "",
                Product_Brand: "",
                Product_Merchant: "",
                Product_SellPrice: 0,
                Product_OriPrice: 0,
                Product_Discount: 0,
                Product_BuyCountLimit: 0,
                Product_BuySeriesLimit: 0,
                Product_ColorAlias: "",
                Product_SizeAlias: "",
                Product_VarietyAlias: "",
                Product_Freight: 0,
                Product_Sort: 0,
                Product_ChildClassify1: 0,
                Product_ChildClassify2: 0,
                Product_Hot: 0,
                Product_Commend: 0,
                Product_StockTotal: 0,
                Product_Sales: 0,
                Product_ContactName: "",
                Product_ContactPhone: "",
                Product_ContactAddress: "",
                Product_PinPrice: 0,
                Product_Position: "0,0",
                Product_Longitude: 0,
                Product_Latitude: 0,
                Product_FlashStart: "",
                Product_FlashEnd: "",
                Product_ImageUrl: "",
                Product_ImageBanner: "",
                Product_Brief: "",
                Product_Detail: ""
            },
            typeName: "",
            dateGap: "",
            sellers: [],
            fileList: [],
            orders: [],
            Product_ID: "",
            picListData: [],
            keyword: "",
            brandid: "",
            currentStore: "",
            storeSale: "",
            storeTotal: "",
            storeNum: "",
            store_ImageUrl: "",
            mapShow: false,
            storeEditVisiable: false,
            storeVisiable: false,
            editProductVisiable: false,
            addProductVisiable: false,
            picList: false,
            addTypeVisable: false,
            typeVisiable: false,
            pageindex: "",
            pagesize: "",
            Brands: "",
            total: 0,
            colors: [],
            sizes: [],
            kinds: [],
            storeList: [],
            typeNum: 0,
            storeArr: [],
            firstStoreArr: [],
            starttime: "",
            endtime: ""
        }
    },
    components: {
        Editor
    },
    created() {
        app = this.$.appContext.app
        this.domin = app.domin
        console.log(app.domin)
        this.pullList()
        this.pullBrand()
        this.pullSellers()
    },
    mounted() {},
    watch: {
        colors(val) {
            this.calcStoreArr()
        },
        sizes(val) {
            this.calcStoreArr()
        },
        kinds(val) {
            this.calcStoreArr()
        }
    },
    methods: {
        setVal(e) {
            console.log(e)
        },
        setPageIndx(pgindx) {
            // console.log(e);
            this.pageindex = pgindx;
            this.pullList();
        },
        compareArr() {
            let n = this.storeArr,
                o = this.firstStoreArr
            console.log("new:", n, "old:", o)
            let newN = n.map(item => item.toString())
            let newO = o.map(item => item.toString())
            // 新增项
            let dif = lo.difference(newN, newO)
            console.log("add items:", dif)
            dif.forEach(item => {
                let tempItem = item.split(",")
                let tempModel = this.makeStockModel(tempItem[0], tempItem[1], tempItem[2], this.Product_ID)
                this.insertStock(tempModel)
            })
            // 减少项
            let dis = lo.difference(newO, newN)
            console.log("disapper items:", dis)
            dis.forEach(item => {
                let tempItem = item.split(",")
                this.storeList.forEach(itemStore => {
                    if (tempItem[0] == itemStore.Stock_Color && tempItem[1] == itemStore.Stock_Size && tempItem[2] == itemStore.Stock_Variety) {
                        this.deleteStock(itemStore.Stock_ID)
                    }
                })
            })
            // 操作结束,替换firstStoreArr 为当前修改后的值
            this.firstStoreArr = this.storeArr
        },
        insertStock(model) {
            app.wpost({
                url: "/AdminAjax/MerchantProduct.ashx?type=stockinsert",
                data: {
                    model: JSON.stringify(model)
                },
                succ: res => {
                    this.getStoreList(this.Product_ID)
                    console.log("新增成功")
                }
            });
        },
        deleteStock(id) {
            app.wpost({
                url: "/AdminAjax/MerchantProduct.ashx?type=stockdelete",
                data: {
                    id: id
                },
                succ: res => {
                    this.getStoreList(this.Product_ID)
                    console.log("删除成功")
                }
            });
        },
        editStore(item) {
            this.storeEditVisiable = true
            this.currentStore = item
            this.storeSale = this.currentStore.Stock_SellPrice
            this.storeTotal = this.currentStore.Stock_Total
            this.storeNum = this.currentStore.Stock_Number
            this.store_ImageUrl = this.currentStore.Stock_ImageUrl
        },
        sendEditStore() {
            this.currentStore.Stock_SellPrice = this.storeSale;
            this.currentStore.Stock_Total = this.storeTotal;
            this.currentStore.Stock_Number = this.storeNum;
            this.currentStore.Stock_ImageUrl = this.store_ImageUrl;
            app.wpost({
                url: "/AdminAjax/MerchantProduct.ashx?type=stockupdate",
                data: {
                    model: JSON.stringify(this.currentStore)
                },
                succ: res => {
                    this.getStoreList(this.Product_ID)
                    this.storeEditVisiable = false
                    console.log("修改成功")
                }
            });
        },
        makeStockModel(Stock_Color, Stock_Size, Stock_Variety, Product_ID) {
            return {
                Stock_Product: Product_ID,
                Stock_Color: Stock_Color,
                Stock_Size: Stock_Size,
                Stock_Variety: Stock_Variety,
                Stock_SellPrice: 0,
                Stock_Total: 0,
                Stock_ImageUrl: "",
                Stock_Number: ""
            }
        },
        editReStore(item) {
            this.Product_ID = item.Product_ID
            Promise.all([this.getColors(item.Product_ID), this.getSizes(item.Product_ID), this.getKinds(item.Product_ID)]).then(res => {
                this.firstCalcStoreArr()
                console.log("promise all res:", res)
            })
            this.getStoreList(item.Product_ID)
            this.storeVisiable = true
        },
        calcDescartes(array) {
            if (array.length < 2) return array[0] || [];
            return [].reduce.call(array, function (col, set) {
                var res = [];
                col.forEach(function (c) {
                    set.forEach(function (s) {
                        var t = [].concat(Array.isArray(c) ? c : [c]);
                        t.push(s);
                        res.push(t);
                    })
                });
                return res;
            });
        },
        calcStoreArr() {
            if (this.colors.length >= 1 && this.sizes.length >= 1 && this.kinds.length >= 1) {
                let storeArr = this.calcDescartes([this.colors, this.sizes, this.kinds])
                console.log("storeArr:", storeArr)
                this.storeArr = storeArr
                return storeArr
            } else {
                // console.log("请填写三项至少一个参数")
                return []
            }
        },
        firstCalcStoreArr() {
            this.firstStoreArr = this.calcStoreArr()
            console.log("firstStoreArr:", this.firstStoreArr)
        },
        sendStore() {},
        delItem(indx, num) {
            if (num == 0) {
                this.colors.splice(indx, 1)
            } else if (num == 1) {
                this.sizes.splice(indx, 1)
            } else if (num == 2) {
                this.kinds.splice(indx, 1)
            }
        },
        addType(num) {
            this.typeNum = num
            this.addTypeVisable = true
        },
        addTypeInArr() {
            if (this.typeName == '') {
                this.$message("请填写内容")
                return false
            }
            if (this.typeNum == 0) {
                if (!this.colors.includes(this.typeName)) {
                    this.colors.push(this.typeName)
                } else {
                    this.$message("已有相同的名称")
                }
            } else if (this.typeNum == 1) {
                if (!this.sizes.includes(this.typeName)) {
                    this.sizes.push(this.typeName)
                } else {
                    this.$message("已有相同的名称")
                }
            } else if (this.typeNum == 2) {
                if (!this.kinds.includes(this.typeName)) {
                    this.kinds.push(this.typeName)
                } else {
                    this.$message("已有相同的名称")
                }
            }
            this.addTypeVisable = false
            this.typeName = ''
        },
        getStoreList(id) {
            this.ipst(
                "/AdminAjax/MerchantProduct.ashx?type=stockselect", {
                    productid: id
                },
                res => {
                    this.storeList = res.list
                }
            );
        },
        getColors(id) {
            return new Promise((resolve, reject) => {
                this.ipst(
                    "/AdminAjax/MerchantProduct.ashx?type=stockcolorselect", {
                        productid: id
                    },
                    res => {
                        this.colors = res.list.map(item => item.Stock_Color)
                        return resolve(res)
                    }
                );
            })
        },
        getSizes(id) {
            return new Promise((resolve, reject) => {
                this.ipst(
                    "/AdminAjax/MerchantProduct.ashx?type=stocksizeselect", {
                        productid: id
                    },
                    res => {
                        this.sizes = res.list.map(item => item.Stock_Size)
                        return resolve(res)
                    }
                );
            })
        },
        getKinds(id) {
            return new Promise((resolve, reject) => {
                this.ipst(
                    "/AdminAjax/MerchantProduct.ashx?type=stockvarietyselect", {
                        productid: id
                    },
                    res => {
                        this.kinds = res.list.map(item => item.Stock_Variety)
                        return resolve(res)
                    }
                );
            })
        },
        searchKeyWord() {
            this.pageindex = 1;
            this.pullList()
        },
        pullBrand() {
            app.wpost({
                url: "/AdminAjax/MerchantBrand.ashx?type=select",
                data: null,
                succ: res => {
                    this.Brands = res.list
                }
            });
        },
        coverFormData4Val() {
            this.formData.Product_ChildClassify1 = Number(this.formData.Product_ChildClassify1);
            this.formData.Product_ChildClassify2 = Number(this.formData.Product_ChildClassify2);
            this.formData.Product_Hot = Number(this.formData.Product_Hot);
            this.formData.Product_Commend = Number(this.formData.Product_Commend);
            let PosArr = this.formData.Product_Position.split(",")
            this.formData.Product_Longitude = PosArr[1]
            this.formData.Product_Latitude = PosArr[0]
        },
        addProduct() {
            this.addProductVisiable = true;
            this.formData.Product_Name = "";
            this.formData.Product_Brand = "";
            this.formData.Product_Merchant = JSON.parse(sessionStorage.getItem("userInfo")).merchant.Merchant_ID;
            this.formData.Product_SellPrice = 0;
            this.formData.Product_OriPrice = 0;
            this.formData.Product_Discount = 0;
            this.formData.Product_ColorAlias = "";
            this.formData.Product_SizeAlias = "";
            this.formData.Product_VarietyAlias = "";
            this.formData.Product_Freight = 0;
            this.formData.Product_Sort = 0;
            this.formData.Product_ChildClassify1 = 0;
            this.formData.Product_ChildClassify2 = 0;
            this.formData.Product_Hot = 0;
            this.formData.Product_Commend = 0;
            this.formData.Product_StockTotal = 0;
            this.formData.Product_Sales = 0;
            this.formData.Product_ContactName = "";
            this.formData.Product_ContactPhone = "";
            this.formData.Product_ContactAddress = "";
            this.formData.Product_PinPrice = 0;
            this.formData.Product_Position = "0,0";
            this.formData.Product_Longitude = 0;
            this.formData.Product_Latitude = 0;
            this.formData.Product_FlashStart = "";
            this.formData.Product_FlashEnd = "";
            this.formData.Product_ImageUrl = "";
            this.formData.Product_ImageBanner = "";
            this.formData.Product_Brief = "";
            this.formData.Product_Detail = "";
            this.dateGap = []
        },
        beforeEdit(item) {
            this.editProductVisiable = true
            this.formData = {
                ...item
            }
            this.formData.Product_ChildClassify1 = Boolean(this.formData.Product_ChildClassify1);
            this.formData.Product_ChildClassify2 = Boolean(this.formData.Product_ChildClassify2);
            this.formData.Product_Hot = Boolean(this.formData.Product_Hot);
            this.formData.Product_Commend = Boolean(this.formData.Product_Commend);
            this.dateGap = [item.Product_FlashStart, item.Product_FlashEnd]
            this.$set(this.formData, "Product_Position", item.Product_Latitude + "," + item.Product_Longitude)
            this.$set(this.formData, "Product_ImageUrl", item.Product_ImageUrl)
            this.$set(this.formData, "Product_ImageBanner", item.Product_ImageBanner)
        },
        sureToeditProduct() {
            this.coverFormData4Val()
            app.wpost({
                url: "/AdminAjax/MerchantProduct.ashx?type=update",
                data: {
                    model: JSON.stringify(this.formData),
                },
                succ: (res) => {
                    this.$message("修改成功");
                    this.editProductVisiable = false
                    this.pullList()
                }
            });
        },
        sureToaddProduct() {
            if (this.formData.Product_Name == "") {
                this.$message("请填写产品名")
                return false
            }
            if (this.formData.Product_FlashStart == "" || this.formData.Product_FlashEnd == "") {
                this.$message("报名时间不能为空")
                return false
            }
            this.coverFormData4Val()
            app.wpost({
                url: "/AdminAjax/MerchantProduct.ashx?type=insertmerchant",
                data: {
                    model: JSON.stringify(this.formData),
                },
                succ: (res) => {
                    this.$message("添加成功");
                    this.addProductVisiable = false
                    this.pullList()
                }
            });
        },
        setDate(e) {
            this.formData.Product_FlashStart = e[0]
            this.formData.Product_FlashEnd = e[1]
        },
        setRangDate(e) {
            this.starttime = e[0]
            this.endtime = e[1]
        },
        UploadImg(res) {
            this.$set(this.formData, 'Product_ImageUrl', res.url)
            console.log("img url", res.url)
        },
        UploadImgBanner(res) {
            this.$set(this.formData, 'Product_ImageBanner', res.url)
            console.log("imgBanner url", res.url)
        },
        storeUploadImg(res) {
            this.store_ImageUrl = res.url
        },
        successUploadImg(res, file, fileList) {
            console.log("res:", res.url)
            let imgsUrl = [res.url]
            app.wpost({
                url: "/AdminAjax/MerchantImages.ashx?type=insertlist",
                data: {
                    id: this.Product_ID,
                    url: JSON.stringify(imgsUrl)
                },
                succ: (res) => {
                    this.pullPicList(that.Product_ID)
                }
            });
        },
        deletePic(item) {
            app.wpost({
                url: "/AdminAjax/MerchantImages.ashx?type=delete",
                data: {
                    id: item.Images_ID,
                },
                succ: (res) => {
                    this.pullPicList(item.Images_Product)
                }
            });
        },
        showPicList(item) {
            this.Product_ID = item.Product_ID
            this.pullPicList(item.Product_ID)
        },
        pullSellers() {
            app.wpost({
                url: "/AdminAjax/Merchant.ashx?type=select",
                data: {
                    pagesize: 99
                },
                succ: (res) => {
                    let templist = res.list
                    templist.unshift({
                        Merchant_ID: 0,
                        Merchant_Name: '平台自营'
                    })
                    this.sellers = templist
                    console.log("sellers list:", this.sellers)
                }
            });
        },
        pullPicList(id) {
            app.wpost({
                url: "/AdminAjax/MerchantImages.ashx?type=select",
                data: {
                    product: id,
                    imagestype: 0
                },
                succ: (res) => {
                    this.picList = true
                    this.picListData = res.list
                }
            });
        },
        unselfOrder(id) {
            app.wpost({
                url: "/AdminAjax/MerchantProduct.ashx?type=updateshelf",
                data: {
                    id: id,
                },
                succ: (res) => {
                    // console.log("res:", res);
                    this.$message("下架成功");
                    this.pullList()
                }
            });
        },
        onselfOrder(id) {
            app.wpost({
                url: "/AdminAjax/MerchantProduct.ashx?type=updateshelf",
                data: {
                    id: id,
                },
                succ: (res) => {
                    // console.log("res:", res);
                    this.$message("上架成功");
                    this.pullList()
                }
            });
        },
        deletOrder(id) {
            app.wpost({
                url: "/AdminAjax/MerchantProduct.ashx?type=delete",
                data: {
                    id: id,
                },
                succ: (res) => {
                    // console.log("res:", res);
                    this.$message("删除成功");
                    this.pullList()
                }
            });
        },
        exprotWalfare() {
            app.wpost({
                url: "/AdminAjax/MerchantOrders.ashx?type=exporttoexcel",
                data: {
                    starttime: this.starttime,
                    endtime: this.endtime,
                },
                succ: (res) => {
                    window.open(this.domin + res.url, "_blank");
                }
            });
        },
        pullList() {
            let that = this;
            app.wpost({
                url: "/AdminAjax/MerchantProduct.ashx?type=selectmerchant",
                data: {
                    keyword: this.keyword,
                    brandid: this.brandid,
                    pageindex: this.pageindex,
                    pagesize: this.pagesize
                },
                succ: (res) => {
                    // console.log("res:", res);
                    this.orders = res.list;
                    this.total = res.count;
                }
            });
        },
    }
}
</script>

<style scoped>
.sixa {
    padding: 3px 12px;
}

.sixa .el-row {
    margin: 12px 3px;
}

.typesWrp {
    padding: 12px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    border-radius: 8px;
}

.btm-btns {
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.btm-btns .el-button {
    margin: 0 8px;
}

.imgTable img {
    width: 120px;
}

.imgUploadWrp {
    padding-top: 26px;
    text-align: right;
}

.searchwrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.searchwrp label {
    width: 60px;
}

.el-table .el-button {
    margin-left: 8px;
}

.typesWrp .el-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 6px;
    padding-bottom: 10px;
}

.typesWrp span.typeitem {
    position: relative;
    display: inline-block;
    padding-right: 16px;
    margin: 5px 8px
}

.typesWrp span.typeitem:hover i {
    position: absolute;
    right: 0;
    top: 2px;
    display: inline-block;
}

.typesWrp i {
    display: none;
    cursor: pointer;
}
</style>
