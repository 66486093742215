<template>
<editor v-model="content" :init="init" :disabled="disabled" @onClick="onClick"></editor>
</template>

<script>
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import { ajax } from "jquery";
import "tinymce/icons/default/icons";
import "tinymce/themes/silver";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/preview";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/advlist";
import "tinymce/plugins/codesample";
import "tinymce/plugins/hr";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/charmap";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/autosave";
import "tinymce/plugins/autoresize";
export default {
    components: {
        Editor,
    },
    props: {
        value: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        plugins: {
            type: [String, Array],
            default: "preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr nonbreaking insertdatetime advlist lists wordcount imagetools textpattern autosave autoresize ",
        },
        toolbar: {
            type: [String, Array],
            default: "ImgCover | code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link codesample | alignleft aligncenter alignright alignjustify outdent indent  formatpainter | \
    styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
    table image media charmap hr pagebreak insertdatetime |  fullscreen preview",
        },
    },
    data() {
        return {
            //初始化配置
            init: {
                language_url: "/static/tinymce/langs/zh_CN.js",
                language: "zh_CN",
                skin_url: "/static/tinymce/skins/ui/oxide",
                height: 770,
                min_height: 770,
                max_height: 770,
                toolbar_mode: "wrap",
                plugins: this.plugins,
                toolbar: this.toolbar,
                content_style: "p {margin: 5px 0;}",
                fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
                font_formats: "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
                branding: false,
                setup: function (editor) {

                    editor.ui.registry.addButton('ImgCover', {

                        text: '转本地图片',

                        onAction: async function () {
                            function uploadImg(url) {
                                return new Promise((resolve, reject) => {
                                    ajax({
                                      method:'post',
                                      url:'/AdminAjax/Property.ashx?type=down',
                                      data:{
                                        url:url
                                      },
                                      success:(res)=>{
                                        resolve(res.url)
                                      },
                                      failure:err=>{
                                        reject(err)
                                      }
                                    })
                                })

                            }
                            
                            let imgs = editor.getDoc().querySelectorAll("img")

                            let imgPromise = []
                            for (let i = 0; i < imgs.length; i++) {
                                imgPromise.push(uploadImg(imgs[i].src))
                            }
                            let realImgsPromise = await Promise.all(imgPromise)
                            
                            for (let n = 0; n < realImgsPromise.length; n++) {
                                imgs[n].setAttribute('src',realImgsPromise[n])
                            }


                            // let domBg = editor.getDoc().querySelectorAll("section")

                            // console.log("domBg",domBg)

                            // for(let d = 0; d<domBg.length;d++){
                            //     if(!!domBg[d].style.backgroudImage){
                            //         console.log( "backgroudImage  :", domBg[d].style.backgroudImage)
                            //     }else{
                            //         console.log("none")
                            //     }
                            // }

                            
                        }
                    })
                },
                images_upload_handler: (blobInfo, success, failure) => {
                    const img = "data:image/jpeg;base64," + blobInfo.base64();
                    success(img);
                    failure(err)
                },
                file_picker_callback: function (callback, value, meta) {
                    //文件分类
                    var filetype =
                        ".pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx,.ppt, .pptx, .mp3, .mp4";
                    //后端接收上传文件的地址
                    var upurl = "";
                    //为不同插件指定文件类型及后端地址
                    switch (meta.filetype) {
                        case "image":
                            filetype = ".jpg, .jpeg, .png, .gif";
                            upurl = "/Ajax/upload_json.ashx?dir=image";
                            break;
                        case "file":
                            filetype = ".pdf,.doc, .docx, .xls, .xlsx";
                            upurl = "/Ajax/upload_json.ashx?dir=file";
                            break;
                    }
                    //模拟出一个input用于添加本地文件
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("name", "imgFile")
                    input.setAttribute("accept", filetype);
                    input.click();
                    input.onchange = function () {
                        var file = this.files[0];

                        var xhr, formData;
                        console.log(file.name);
                        xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open("POST", upurl);
                        xhr.onload = function () {
                            var json;
                            if (xhr.status != 200) {
                                alert("HTTP Error: " + xhr.status);
                                return;
                            }
                            json = JSON.parse(xhr.responseText);

                            if (!json || json.result != "success") {
                                alert("Invalid JSON: " + json.message);
                                return;
                            }
                            callback("https://www.visasiv.cn" + json.url);
                        };
                        formData = new FormData();
                        formData.append("imgFile", file, file.name);
                        xhr.send(formData);
                    };
                },
            },
            content: this.value,
        };
    },
    mounted() {
        tinymce.init({});
    },
    methods: {
        onClick(e) {
            // console.log(e)
        },
        uploadImg(url) {
            return new Promise((resolve, reject) => {
                this.ipst('/AdminAjax/Property.ashx?type=down', {
                    url: url
                }, res => {
                    resolve(res.url)
                })
            })

        }
    },
    watch: {
        value(newValue) {
            this.content = newValue;
        },
        async content(newValue) {
            this.$emit("input", newValue);
            // // 提取文本中的img标签
            // let reg = /<img.*\/>/ig
            // let mg = newValue.match(reg)
            // // console.log("mg", mg)

            // // 提取 img内的src路径
            // let srcs = mg.map(img => {
            //     let item = img.match(/src=".*"/ig)[0]
            //     return item.substring(5, item.length - 1)
            // })
            // console.log("srcs", srcs)

            // let newSrcs = srcs.map(src => {
            //     return this.uploadImg(src)
            // })

            // let trueSrc = await Promise.all(newSrcs)

            // console.log("trueSrc", trueSrc)
        },
    },
};
</script>

<style>
</style>
